.login{
    display: block;    
    max-width: 450px;  
    padding: 10px;
    
}
.login__background{
     /* background: url(../assets/images/wp.jpg) no-repeat center center fixed;  */
     background-color: rgba(255,255,255, 0.8);
    background-size: cover;
   /*  height: 100%;    */
    height:100vh; 
    box-sizing: border-box;
}
.login__title{
    text-align: center;
    color: black;
    margin: 2em 0;
}
.login__links{
    list-style: none;
    padding-left: 0px;
    display: flex;    
}
.login__links li{
   margin-right: 20px;
}
.login__links li a{
    text-decoration: none;
    color: black
}
.login__input{
    border: none;
    border-radius: 15px;        
    height: 50px;
    margin-bottom: 10px;
    padding-left: 15px;
    width: 100%;
}
.login__input::after {
    content: " \21E6"; /* Hexadecimal for Unicode Leftwards white arrow*/
    display: inline;
  }
